import "./style.scss";
import { Content } from "antd/es/layout/layout";
import DesignSystemTheme from "../../shared/component/DesignSystemTheme";
import { Breadcrumb, Card, Col, Flex, notification, Row, Space } from "antd";
import { FormDs, Form, InputDs, SelectDs, ButtonDs } from "design-system";
import { provincesOptions } from "../../shared/service/province-list-option";
import { DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import ConfirmModal from "../../shared/component/ConfirmModal";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import storeMasterService from "./services/store-master-service";
import { useQuery } from "@tanstack/react-query";
import { NotificationComponent } from "../../shared/component/NotificationComponent";
import { phoneNumberValidator } from "../../utils/validators";
import { formatPhoneNumber } from "../../utils/normalizers";
import LocalstorageService from "../../shared/service/Localstorage.service";
import { StoreType } from "./models/store";

export interface UpdateStoreFormValues {
  store_name: string;
  province: string;
  latlong: string;
  mobile_phone: string;
  remark: string;
}

enum UpdateStoreModalState {
  CONFIRM_UPDATE = "CONFIRM_UPDATE",
  CANCEL_UPDATE = "CANCEL_UPDATE",
  CONFIRM_DELETE = "CONFIRM_DELETE",
}
const UpdateStore = () => {
  const provinces = provincesOptions.slice(1);
  const [confirmUpdateModalState, setConfirmUpdateModalState] = useState<UpdateStoreModalState>();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [notificationComponent, contextHolderNoti] = notification.useNotification();
  const userRole = LocalstorageService.getCurrentRole();
  const openNotification = (type: "success" | "error", topic = "", message = "") => {
    NotificationComponent({
      notification: notificationComponent,
      type,
      topic,
      message,
    });
  };

  const { data, error } = useQuery({
    queryKey: ["getStore"],
    queryFn: () =>
      storeMasterService.getStore(params.store_number || "").then((res) => {
        const data = { ...res, latlong: `${res.lat},${res.long}` };
        form.setFieldsValue(data);
        return res;
      }),
    retry: false,
    refetchOnWindowFocus: false,
  });

  if (error) {
    openNotification(
      "error",
      "ดึงข้อมูลร้านค้าไม่สำเร็จ",
      "เกิดข้อผิดพลาดในการดึงข้อมูลหรือไม่ได้เชื่อมต่ออินเทอร์เน็ต กรุณาลองใหม่อีกครั้ง",
    );
  }

  const onConfirmSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        setConfirmUpdateModalState(UpdateStoreModalState.CONFIRM_UPDATE);
      })
      .catch((err) => console.log(err));
  };

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const formValues: UpdateStoreFormValues = form.getFieldsValue();
      const payload: Partial<StoreType> = {
        store_name: formValues.store_name,
        province: formValues.province,
        lat: formValues.latlong.split(",")[0].trim(),
        long: formValues.latlong.split(",")[1].trim(),
        mobile_phone: formValues.mobile_phone,
        remark: formValues.remark,
      };
      const res = await storeMasterService.updateStore(params.store_number || "", payload);
      setConfirmUpdateModalState(undefined);
      storeMasterService.storeUpdated.next({ ...res, is_updated_store: true });
      navigate("/store-master");
    } catch (err) {
      openNotification(
        "error",
        "แก้ไขข้อมูลร้านค้าไม่สำเร็จ",
        "เกิดข้อผิดพลาดในการบันทึกข้อมูลหรือไม่ได้เชื่อมต่ออินเทอร์เน็ต กรุณาลองใหม่อีกครั้ง",
      );
    } finally {
      setIsLoading(false);
    }
  };

  const onDelete = async () => {
    try {
      setIsLoading(true);
      await storeMasterService.deleteStore(params.store_number || "");
      storeMasterService.signalDeleteStore.next(true);
      navigate("/store-master");
    } catch (err) {
      openNotification(
        "error",
        "ลบร้านค้าไม่สำเร็จ",
        "เกิดข้อผิดพลาดในการลบข้อมูลหรือไม่ได้เชื่อมต่ออินเทอร์เน็ต กรุณาลองใหม่อีกครั้ง",
      );
    } finally {
      setIsLoading(false);
    }
  };

  const trimValue = (value: string) => {
    return value.trim();
  };

  return (
    <DesignSystemTheme>
      <Content className="store-master-content">
        <div>
          <Breadcrumb
            separator=">"
            items={[
              {
                title: "Store Master",
                href: "/store-master",
              },
              {
                title: "ข้อมูลร้านค้า",
              },
            ]}
          />
        </div>
        <div className="page-header" style={{ display: "flex", alignItems: "center", marginBottom: 16 }}>
          <h2>ข้อมูลร้านค้า</h2>
        </div>
        <FormDs layout="vertical" form={form} onFinish={onConfirmSubmit}>
          <Card>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <h3>รายละเอียด</h3>
              </Col>
              <Col span={24}>
                <Card>
                  <Flex gap={16}>
                    <Form.Item
                      label="บริษัท"
                      style={{ margin: "0" }}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <p>{data?.company ? data?.company : "-"}</p>
                    </Form.Item>
                  </Flex>
                  <Flex gap={16}>
                    <Form.Item
                      label="รหัสร้่านค้า"
                      style={{ margin: "0" }}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <InputDs placeholder="กรุณากรอกรหัสร้านค้า" hidden></InputDs>
                      <p style={{ width: "330px" }}>{data?.store_number ? data.store_number : "-"}</p>
                    </Form.Item>
                    <Form.Item
                      name="store_name"
                      label="ชื่อร้านค้า"
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุชื่อร้านค้า",
                        },
                      ]}
                      normalize={trimValue}
                    >
                      <InputDs placeholder="กรุณากรอกชื่อร้านค้า" />
                    </Form.Item>
                  </Flex>
                  <Flex gap={16}>
                    <Form.Item
                      name="province"
                      label="จังหวัด"
                      rules={[
                        {
                          required: true,
                          message: "กรุณาเลือกจังหวัด",
                        },
                      ]}
                      required
                    >
                      <SelectDs
                        className="ant-select-lg ant-select-in-form-item select-ds"
                        placeholder="กรุณาเลือกจังหวัด"
                        style={{ width: "330px" }}
                        options={provinces}
                        showSearch
                      />
                    </Form.Item>
                    <Form.Item
                      name="latlong"
                      label="พิกัดร้านค้า"
                      rules={[
                        {
                          pattern: new RegExp(/^\s*-?\d+(\.\d+)?\s*,\s*-?\d+(\.\d+)?\s*$/),
                          message: "รูปแบบ lat,long ไม่ถูกต้อง",
                        },
                        {
                          required: true,
                          message: "กรุณาระบุพิกัดร้านค้า",
                        },
                      ]}
                      normalize={trimValue}
                    >
                      <InputDs placeholder="กรุณากรอก lat,long" />
                    </Form.Item>
                  </Flex>
                  <Flex gap={16}>
                    <Form.Item
                      name="mobile_phone"
                      label="เบอร์โทร"
                      help="ระบุเบอร์โทร(ตัวเลข), ไม่มีเว้นวรรค ไม่มีอักขระพิเศษ"
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุเบอร์โทร",
                        },
                        {
                          validator: phoneNumberValidator,
                        },
                      ]}
                      normalize={(value) => formatPhoneNumber(value)}
                    >
                      <InputDs placeholder="กรุณากรอกเบอร์โทร" />
                    </Form.Item>
                    <Form.Item name="remark" label="remark">
                      <InputDs placeholder="กรุณากรอกหมายเหตุ" />
                    </Form.Item>
                  </Flex>
                  <div style={{ marginTop: "16px", display: "flex", justifyContent: "flex-end" }}>
                    <Space>
                      <ButtonDs
                        type="default"
                        onClick={() => {
                          setConfirmUpdateModalState(UpdateStoreModalState.CANCEL_UPDATE);
                        }}
                      >
                        ยกเลิก
                      </ButtonDs>
                      <ButtonDs type="primary" htmlType="submit">
                        บันทึก
                      </ButtonDs>
                    </Space>
                  </div>
                </Card>
              </Col>
              {userRole.includes("admin") ? (
                <Col span={24}>
                  <Card>
                    <h4>ลบร้านค้า</h4>
                    <p>หากลบร้านค้า ข้อมูลทั้งหมดจะถูกลบและไม่สามารถกู้คืนได้</p>
                    <ButtonDs
                      type="primary"
                      danger
                      onClick={() => setConfirmUpdateModalState(UpdateStoreModalState.CONFIRM_DELETE)}
                    >
                      <DeleteOutlined />
                      ลบร้านค้า
                    </ButtonDs>
                  </Card>
                </Col>
              ) : null}
            </Row>
          </Card>
        </FormDs>
      </Content>
      <ConfirmModal
        title={
          <Space>
            <QuestionCircleOutlined /> ยืนยันการเปลี่ยนแปลงข้อมูล
          </Space>
        }
        onClickConfirmCancel={() => {
          setConfirmUpdateModalState(undefined);
        }}
        onClickConfirmOK={onSubmit}
        open={confirmUpdateModalState === UpdateStoreModalState.CONFIRM_UPDATE}
        loading={isLoading}
      >
        <p>ยืนยันการเปลี่ยนแปลงข้อมูลร้านค้า {data?.store_number} หรือไม่ ?</p>
      </ConfirmModal>
      <ConfirmModal
        title={
          <Space>
            <QuestionCircleOutlined /> ออกจากหน้านี้หรือไม่
          </Space>
        }
        onClickConfirmCancel={() => {
          setConfirmUpdateModalState(undefined);
        }}
        onClickConfirmOK={() => {
          navigate("/store-master");
        }}
        open={confirmUpdateModalState === UpdateStoreModalState.CANCEL_UPDATE}
      >
        <p>หากออกจากหน้านี้ ข้อมูลทั้งหมดจะไม่ถูกบันทึก ต้องการออกจากหน้านี้หรือไม่ ?</p>
      </ConfirmModal>
      <ConfirmModal
        open={confirmUpdateModalState === UpdateStoreModalState.CONFIRM_DELETE}
        title={
          <Space>
            <QuestionCircleOutlined /> ยืนยันการลบข้อมูลร้านค้า
          </Space>
        }
        onClickConfirmCancel={() => setConfirmUpdateModalState(undefined)}
        onClickConfirmOK={onDelete}
        loading={isLoading}
      >
        <div className="confirm-create-user-modal-content">
          <p>
            ยืนยันการลบข้อมูลร้านค้า {data?.store_number} ({data?.store_name}) หรือไม่ ?{" "}
          </p>
          <p>หากลบข้อมูลร้านค้า ข้อมูลทั้งหมดจะถูกลบและไม่สามารถกู้คืนได้</p>
        </div>
      </ConfirmModal>
      {contextHolderNoti}
    </DesignSystemTheme>
  );
};

export default UpdateStore;
